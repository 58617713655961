import React, { useState, useEffect } from 'react';
import {
    Progress,
    useColorMode,
    Input,
    Tag,
    TagLabel,
    TagCloseButton,
    Button,
    Flex,
    Select,
    Box,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import Card from './Card.js';
import * as Consts from './Consts.js';

const FilterBox = ({ children, ...props }) => (
    <Box my={[2, 2, 2, 2, 0]} {...props}>
        {children}
    </Box>
);

function FilteredPaginator(props) {
    const itemsPerPage = 30;

    const [show, setShow] = React.useState(false);
    const [filteredItems, setFilteredItems] = useState([]);

    const [currPage, setCurrPage] = useState(0);

    const [signFilter, setSignFilter] = useState('');
    const [alignmentFilter, setAlignmentFilter] = useState('');

    const [sortBy, setSortBy] = useState(0);
    const [availability, setAvailability] = useState(0);

    const [searchText, setSearchText] = useState('');

    useColorMode();

    const handleSignChange = (event) => {
        setSignFilter(event.target.value);
        resetPage();
    };

    const handleAlignmentChange = (event) => {
        setAlignmentFilter(event.target.value);
        resetPage();
    };

    const handleSearchChange = async (event) => {
        setSearchText(event.target.value);
        resetPage();
    };

    const handleSortChange = (event) => {
        let parsed = parseInt(event.target.value);
        if (isNaN(parsed)) {
            parsed = 0;
        }
        setSortBy(parsed);
        resetPage();
    };

    const handleAvailabilityChange = (event) => {
        let parsed = parseInt(event.target.value);
        if (isNaN(parsed)) {
            parsed = 0;
        }
        setAvailability(parsed);
        resetPage();
    };

    const handleToggle = () => setShow(!show);

    useEffect(() => {
        setFilteredItems(
            props.items
                .filter(
                    (i) =>
                        (i['sign'] === signFilter || signFilter === '') &&
                        (i['alignment'] === alignmentFilter || alignmentFilter === '') &&
                        (i['searchable'].toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
                            searchText.length === 0) &&
                        ((!i['claimed'] && availability === 1) ||
                            (i['price'] > 0 && availability === 2) ||
                            availability === 0)
                )
                .sort(function(a, b) {
                    switch (sortBy) {
                        case 0:
                            return true;
                        case 1:
                            return b['attribtotal'] - a['attribtotal'];
                        case 2:
                            return a['attribtotal'] - b['attribtotal'];
                        case 3:
                            return b['level'] - a['level'];
                        case 4:
                            return a['level'] - b['level'];
                        default:
                            return false;
                    }
                })
        );
    }, [signFilter, alignmentFilter, searchText, sortBy, props.items, availability]);

    function getItems() {
        if (filteredItems.length <= itemsPerPage) {
            return filteredItems;
        }

        if (filteredItems.length <= currPage * itemsPerPage) {
            return [];
        }

        var lastItem = (currPage + 1) * itemsPerPage;
        if (filteredItems.length <= (currPage + 1) * itemsPerPage) {
            lastItem = filteredItems.length;
        }

        return filteredItems.slice(currPage * itemsPerPage, lastItem);
    }

    function isLastPage() {
        return (currPage + 1) * itemsPerPage >= filteredItems.length;
    }

    function prevPage() {
        setCurrPage(currPage - 1);
        document.getElementById('paginator-top').scrollIntoView();
    }

    function nextPage() {
        setCurrPage(currPage + 1);
        document.getElementById('paginator-top').scrollIntoView();
    }

    function toPage(page) {
        setCurrPage(page);
        document.getElementById('paginator-top').scrollIntoView();
    }

    function hasFilters() {
        return signFilter !== '' || alignmentFilter !== '' || searchText !== '' || availability !== 0;
    }

    function clearFilters() {
        setSignFilter('');
        setAlignmentFilter('');
        setSearchText('');
        setAvailability(0);
    }

    function resetPage() {
        setCurrPage(0);
    }

    return (
        <Box w="100%" id="paginator">
            <Flex
                id="controls"
                bg={props.accentBg}
                p="10px"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box flexGrow={1} id="paginator-top" width={['100px', 'auto', 'auto', 'auto', 'auto']}>
                    <Input value={searchText} onChange={handleSearchChange} placeholder="Search" />
                </Box>

                <Flex
                    mx={5}
                    justifyContent="center"
                    flexDirection={['column', 'row', 'row', 'row', 'row']}
                    alignItems="center"
                >
                    <Select value={availability} placeholder="Availability" onChange={handleAvailabilityChange}>
                        <option key={0} value={1}>
                            Claimable
                        </option>
                        <option key={1} value={2}>
                            For Sale
                        </option>
                    </Select>
                </Flex>

                <Button
                    aria-label="Filter"
                    display={['block', 'block', 'block', 'block', 'none']}
                    onClick={handleToggle}
                >
                    <FontAwesomeIcon icon={faFilter} />
                </Button>

                <Box
                    display={[
                        show ? 'block' : 'none',
                        show ? 'block' : 'none',
                        show ? 'block' : 'none',
                        show ? 'block' : 'none',
                        'flex',
                    ]}
                    width={['full', 'full', 'full', 'full', 'auto']}
                    alignItems="center"
                    justifyContent="space-around"
                    flexGrow="1"
                >
                    <FilterBox>
                        <Select value={signFilter} placeholder="Sign" onChange={handleSignChange}>
                            {props.signs.map((value, index) => {
                                return (
                                    <option key={index} value={value}>
                                        {value}
                                    </option>
                                );
                            })}
                        </Select>
                    </FilterBox>
                    <FilterBox>
                        <Select value={alignmentFilter} placeholder="Alignment" onChange={handleAlignmentChange}>
                            {props.alignments.map((value, index) => {
                                return (
                                    <option key={index} value={value}>
                                        {value}
                                    </option>
                                );
                            })}
                        </Select>
                    </FilterBox>
                    <FilterBox>
                        <Select value={sortBy} placeholder="Sort by" onChange={handleSortChange}>
                            <option key={0} value={1}>
                                Score high to low
                            </option>
                            <option key={1} value={2}>
                                Score low to high
                            </option>
                            <option key={2} value={3}>
                                Level high to low
                            </option>
                            <option key={3} value={4}>
                                Level low to high
                            </option>
                        </Select>
                    </FilterBox>
                </Box>
            </Flex>
            {hasFilters() ? (
                <Flex>
                    <Consts.FgText m={'10px'}>Filters: </Consts.FgText>
                    {availability === 1 ? (
                        <Tag m={'10px'} size={'sm'} variant="subtle" colorScheme="cyan">
                            <TagLabel>Claimable</TagLabel>
                            <TagCloseButton onClick={() => setAvailability(0)} />
                        </Tag>
                    ) : null}
                    {availability === 2 ? (
                        <Tag m={'10px'} size={'sm'} variant="subtle" colorScheme="cyan">
                            <TagLabel>For Sale</TagLabel>
                            <TagCloseButton onClick={() => setAvailability(0)} />
                        </Tag>
                    ) : null}
                    {signFilter !== '' ? (
                        <Tag m={'10px'} size={'sm'} variant="subtle" colorScheme="cyan">
                            <TagLabel>{signFilter}</TagLabel>
                            <TagCloseButton onClick={() => setSignFilter('')} />
                        </Tag>
                    ) : null}
                    {alignmentFilter !== '' ? (
                        <Tag m={'10px'} size={'sm'} variant="subtle" colorScheme="cyan">
                            <TagLabel>{alignmentFilter}</TagLabel>
                            <TagCloseButton onClick={() => setAlignmentFilter('')} />
                        </Tag>
                    ) : null}
                    {searchText !== '' ? (
                        <Tag m={'10px'} size={'sm'} variant="subtle" colorScheme="cyan">
                            <TagLabel>{searchText}</TagLabel>
                            <TagCloseButton onClick={() => setSearchText('')} />
                        </Tag>
                    ) : null}
                </Flex>
            ) : null}
            <Flex flexDirection="column" justifyContent="center" alignItems="center">
                {props.isLoaded ? (
                    filteredItems.length === 0 ? (
                        <Flex flexDirection="column" justifyContent="center" alignItems="center">
                            <Consts.FgText m={'10px'}>
                                {hasFilters()
                                    ? 'No heroes matching the filters.'
                                    : "This account doesn't own any heroes."}
                            </Consts.FgText>
                            {hasFilters() ? (
                                <Button m={'10px'} colorScheme="blue" onClick={clearFilters}>
                                    Show all heroes
                                </Button>
                            ) : (
                                <Button as="a" m={'10px'} colorScheme="blue" href="/">
                                    Show all heroes
                                </Button>
                            )}
                        </Flex>
                    ) : (
                        <Box>
                            <Flex flexWrap="wrap" justifyContent="space-around" id="cards">
                                {getItems().map((value, index) => {
                                    return <Card value={value} key={value['tokenid']} />;
                                })}
                            </Flex>
                            {filteredItems.length > itemsPerPage ? (
                                <Flex justifyContent="center" alignItems="center">
                                    {currPage === 0 ? null : (
                                        <Button m={'5px'} onClick={prevPage}>
                                            Prev
                                        </Button>
                                    )}
                                    {[
                                        ...Array(
                                            Math.floor((filteredItems.length + itemsPerPage - 1) / itemsPerPage)
                                        ).keys(),
                                    ].map((value, index) => {
                                        return (
                                            <Button
                                                isDisabled={value === currPage}
                                                onClick={() => {
                                                    toPage(value);
                                                }}
                                                m={'5px'}
                                                key={index}
                                            >
                                                {value + 1}
                                            </Button>
                                        );
                                    })}
                                    {isLastPage() ? null : (
                                        <Button m={'5px'} onClick={nextPage}>
                                            Next
                                        </Button>
                                    )}
                                </Flex>
                            ) : null}
                        </Box>
                    )
                ) : (
                    <Flex w="100%" my="100px" flexDirection="column" justifyContent="center" alignItems="center">
                        Loading heroes...
                        <Progress w="200px" size="lg" hasStripe isAnimated value={100} />
                    </Flex>
                )}
            </Flex>
        </Box>
    );
}

export default FilteredPaginator;
