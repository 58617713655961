import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.css';
import App from './App';
import { ChakraProvider, extendTheme, ColorModeScript } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

Sentry.init({
    dsn: 'https://e85aecfeac61415e8f448920f3ea7bbf@o399199.ingest.sentry.io/5561836',
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.0,
});

const breakpoints = createBreakpoints({
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1280px',
});

const overrides = {
    breakpoints,
};

const customTheme = extendTheme(overrides);

ReactDOM.render(
    <React.StrictMode>
        <ChakraProvider theme={customTheme}>
            <ColorModeScript />
            <App />
        </ChakraProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
