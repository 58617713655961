import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { isIE } from 'react-device-detect';
import { ethers } from 'ethers';
import {
    useColorModeValue,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Box,
    Alert,
    AlertIcon,
    Link,
    useToast,
} from '@chakra-ui/react';
import Navbar from './Navbar.js';
import FAQ from './FAQ.js';
import Roadmap from './Roadmap.js';
import Char from './Char.js';
import Main from './Main.js';
import * as Consts from './Consts.js';
import './App.css';

const getWriterContract = (provider, setEthAccount, setEthWriteContract) => {
    const prom = new Promise((resolve, reject) => {
        const signer = provider.getSigner();
        signer
            .getAddress()
            .then((acc) => {
                setEthAccount(acc);

                const contractAbi = [
                    'function claimToken(uint256 token) external payable',
                    'function forSaleToken(uint256 token, uint price) external',
                    'function cancelSaleToken(uint256 token) external',
                    'function buyToken(uint256 token) external payable',
                    'function levelUpToken(uint256 token) external payable',
                ];
                const contract = new ethers.Contract(Consts.ContractAddress, contractAbi, signer);
                setEthWriteContract(contract);

                resolve();
            })
            .catch((e) => {
                reject(e);
            });
    });

    return prom;
};

const getClaimCostRest = (setEthClaimCost, toast) => {
    fetch('https://' + Consts.APIURL + '/claimcost', {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
        .then((res) => {
            if (res.ok) {
                return res.json();
            }

            throw new Error(res.statusText);
        })
        .then((result) => {
            setEthClaimCost(result.claimcost);
        })
        .catch((e) => {
            toast({
                title: 'An error occurred.',
                description: "Can't retrieve claim cost.",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            setEthClaimCost(0);
        });
};

function App() {
    const [ethProvider, setEthProvider] = useState(null);
    const [ethAccount, setEthAccount] = useState(null);
    const [ethReadContract, setEthReadContract] = useState(null);
    const [triedEthReadContract, setTriedEthReadContract] = useState(false);
    const [wrongNetwork, setWrongNetwork] = useState(false);
    const [ethWriteContract, setEthWriteContract] = useState(null);
    const [ethClaimCost, setEthClaimCost] = useState(null);
    const [ethLevelUpCost, setEthLevelUpCost] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [ethPrice, setEthPrice] = useState(null);
    const toast = useToast();

    const bg = useColorModeValue(Consts.bgColor.light, Consts.bgColor.dark);

    useEffect(() => {
        fetch('https://' + Consts.APIURL + '/price', {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }

                throw new Error(res.statusText);
            })
            .then((result) => {
                setEthPrice(result['price']);
            })
            .catch((e) => {
                toast({
                    title: "Can't get current ETH price",
                    description: Consts.ExtractErrorString(e),
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            });

        if (window.ethereum === undefined) {
            setTriedEthReadContract(true);
            getClaimCostRest(setEthClaimCost, toast);
            return;
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        provider.getNetwork().then((r) => {
            if (r.chainId !== Consts.NetworkID) {
                setTriedEthReadContract(true);
                setWrongNetwork(true);
                getClaimCostRest(setEthClaimCost, toast);
                return;
            } else {
                setEthProvider(provider);

                const contractAbi = [
                    'function claimableTokens() external view returns(uint256[] memory)',
                    'function listTokensForSale() external view returns(uint256[] memory)',
                    'function getClaimCost() external view returns(uint)',
                    'function tokenClaimable(uint256 tokenId) external view returns(bool)',
                    'function ownerOf(uint256 tokenId) public view returns(address)',
                    'function salePrice(uint256 token) external view returns(uint)',
                    'function getTokenLevel(uint256 token) external view returns(uint)',
                    'function tokenCanLevelUp(uint256 token) external view returns(uint)',
                    'function getLevelUpCost() external view returns(uint)',
                    'event Minted(uint256 indexed tokenId, uint256 dna)',
                    'event Claimed(uint256 indexed tokenId, address indexed newOwner)',
                    'event ForSale(uint256 indexed tokenId, address indexed owner, uint price)',
                    'event CancelForSale(uint256 indexed tokenId, address indexed owner)',
                    'event Sale(uint256 indexed tokenId, address indexed from, address indexed to, uint price)',
                    'event Transfer(address indexed _from, address indexed _to, uint256 indexed _tokenId)',
                    'event LevelUp(uint256 indexed tokenId, uint newLevel)',
                ];
                const contract = new ethers.Contract(Consts.ContractAddress, contractAbi, provider);
                setEthReadContract(contract);
                setTriedEthReadContract(true);

                contract
                    .getClaimCost()
                    .then((cost) => {
                        setEthClaimCost(cost);
                    })
                    .catch((e) => {
                        toast({
                            title: 'An error occurred.',
                            description: "Can't retrieve claim cost.",
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                        });
                        setEthClaimCost(0);
                    });

                contract
                    .getLevelUpCost()
                    .then((cost) => {
                        setEthLevelUpCost(cost);
                    })
                    .catch((e) => {
                        toast({
                            title: 'An error occurred.',
                            description: "Can't retrieve level up cost.",
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                        });
                        setEthLevelUpCost(0);
                    });

                // check if an account is cached
                getWriterContract(provider, setEthAccount, setEthWriteContract).catch((err) => {
                    // ignore errors
                });
            }
        });
    }, [toast]);

    const ethConnect = () => {
        const prom = new Promise((resolve, reject) => {
            if (ethProvider) {
                window.ethereum
                    .request({ method: 'eth_requestAccounts' })
                    .then(() => {
                        getWriterContract(ethProvider, setEthAccount, setEthWriteContract)
                            .then(() => {
                                resolve();
                            })
                            .catch((e) => {
                                reject(e);
                            });
                    })
                    .catch((e) => {
                        reject(e);
                    });
            } else {
                onOpen();
                resolve();
            }
        });

        return prom;
    };

    const ethClaim = async (tokenid) => {
        if (ethWriteContract) {
            let overrides = {
                value: ethClaimCost,
            };
            let tx = await ethWriteContract.claimToken(tokenid, overrides);
            return tx.wait();
        } else {
            return ethConnect();
        }
    };

    const ethSell = async (tokenid, price) => {
        if (ethWriteContract) {
            let tx = await ethWriteContract.forSaleToken(tokenid, price);
            return tx.wait();
        } else {
            return ethConnect();
        }
    };

    const ethCancelSell = async (tokenid) => {
        if (ethWriteContract) {
            let tx = await ethWriteContract.cancelSaleToken(tokenid);
            return tx.wait();
        } else {
            return ethConnect();
        }
    };

    const ethBuy = async (tokenid, price) => {
        if (ethWriteContract) {
            let overrides = {
                value: price,
            };
            let tx = await ethWriteContract.buyToken(tokenid, overrides);
            return tx.wait();
        } else {
            return ethConnect();
        }
    };

    const ethLevelUp = async (tokenid, price) => {
        if (ethWriteContract) {
            let overrides = {
                value: price,
            };
            let tx = await ethWriteContract.levelUpToken(tokenid, overrides);
            return tx.wait();
        } else {
            return ethConnect();
        }
    };

    if (isIE) {
        return 'Sorry, Internet Explorer is not supported';
    }

    return (
        <Box bg={bg} fontFamily="Noto Sans, Verdana, sans-serif">
            <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
                <ModalOverlay />
                <ModalContent>
                    {wrongNetwork ? (
                        <>
                            <ModalHeader>Mainnet required</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>Please switch your wallet to the Ethereum Mainnet.</ModalBody>
                            <ModalFooter>
                                <Button mr={3} onClick={onClose}>
                                    OK
                                </Button>
                            </ModalFooter>
                        </>
                    ) : (
                        <>
                            <ModalHeader>Wallet Required</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                In order to own collectables on the blockchain, you need to install a crypto wallet
                                (like {Consts.ThirdPartyWallet}).
                            </ModalBody>

                            <ModalFooter>
                                <Button colorScheme="blue" mr={3} onClick={Consts.ThirdPartyWalletOnClick}>
                                    Install {Consts.ThirdPartyWallet}
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
            {triedEthReadContract ? (
                wrongNetwork ? (
                    <Alert status="warning" variant="solid" justifyContent="center">
                        <AlertIcon />
                        You are not connected to the Ethereum Mainnet. Please switch your wallet to the Mainnet.
                    </Alert>
                ) : ethReadContract ? null : (
                    <Alert status="warning" variant="solid" justifyContent="center">
                        <AlertIcon />
                        Please install a crypto wallet in order to claim the heroes!
                        <Link ml="10px" onClick={Consts.ThirdPartyWalletOnClick}>
                            Install {Consts.ThirdPartyWallet}
                        </Link>
                    </Alert>
                )
            ) : null}
            <Alert status="success" variant="solid" justifyContent="center">
                <AlertIcon />
                <b>
                    Leveling system is now online! Enjoy Phase 2. <Link href="/faq#leveling">Learn more</Link>
                </b>
            </Alert>
            <Navbar ethConnect={ethConnect} ethAccount={ethAccount} ethReadContract={ethReadContract} />
            <Router>
                <Switch>
                    <Route exact path="/char/:id([0-9]+)">
                        <Char
                            ethAccount={ethAccount}
                            ethClaim={ethClaim}
                            ethSell={ethSell}
                            ethCancelSell={ethCancelSell}
                            ethClaimCost={ethClaimCost}
                            ethLevelUp={ethLevelUp}
                            ethLevelUpCost={ethLevelUpCost}
                            ethReadContract={ethReadContract}
                            triedEthReadContract={triedEthReadContract}
                            ethBuy={ethBuy}
                            ethPrice={ethPrice}
                        />
                    </Route>
                    <Route exact path="/faq">
                        <FAQ />
                    </Route>
                    <Route exact path="/roadmap">
                        <Roadmap />
                    </Route>
                    <Route exact path="/account/:urlaccount">
                        <Main
                            ethAccount={ethAccount}
                            ethClaim={ethClaim}
                            ethClaimCost={ethClaimCost}
                            ethReadContract={ethReadContract}
                            ethConnect={ethConnect}
                            isAccount={true}
                            ethPrice={ethPrice}
                        />
                    </Route>
                    <Route exact path="/account">
                        <Main
                            ethAccount={ethAccount}
                            ethClaim={ethClaim}
                            ethClaimCost={ethClaimCost}
                            ethReadContract={ethReadContract}
                            ethConnect={ethConnect}
                            isAccount={true}
                            ethPrice={ethPrice}
                        />
                    </Route>
                    <Route path="/">
                        <Main
                            ethAccount={ethAccount}
                            ethClaim={ethClaim}
                            ethClaimCost={ethClaimCost}
                            ethReadContract={ethReadContract}
                            ethConnect={ethConnect}
                            isAccount={false}
                            ethPrice={ethPrice}
                        />
                    </Route>
                </Switch>
            </Router>
        </Box>
    );
}

export default App;
