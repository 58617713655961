import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Link,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Button,
    Tooltip,
    List,
    ListItem,
    Image,
    Box,
    Flex,
    Heading,
    Tag,
    TagLabel,
    useToast,
    Progress,
    Divider,
    useColorModeValue,
    Text,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFistRaised, faRunning, faHeartbeat, faEye, faBook, faTheaterMasks } from '@fortawesome/free-solid-svg-icons';
import { ethers } from 'ethers';
import * as Consts from './Consts.js';

const Feature = ({ title, subtitles, descs, freqs }) => {
    return subtitles.length === 0 ? null : (
        <Box mt={'30px'}>
            <Heading fontFamily="Vollkorn, Georgia, serif" size="lg" mb={2}>
                {title}
            </Heading>
            <List styleType={subtitles.length === 1 ? 'none' : 'square'} stylePosition="outside" spacing={4}>
                {subtitles.map((value, index) => {
                    return (
                        <ListItem key={index}>
                            <strong>{value}</strong> (
                            <Text as="b" fontFamily="Noto Sans, Verdana, sans-serif">
                                {freqs[index]}
                            </Text>{' '}
                            other heroes have this): {descs[index]}
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
};

const Attrib = ({ point, color, icon, name, desc }) => {
    return (
        <Tooltip hasArrow label={desc} placement="bottom">
            <Flex mt={'20px'} alignItems="center">
                <Tag mr={'15px'} minW={'65px'} colorScheme={color} justifyContent="center">
                    <FontAwesomeIcon icon={icon} />
                    <TagLabel as="strong" ml={'5px'}>
                        {name}
                    </TagLabel>
                    <TagLabel as="strong" ml={'5px'}>
                        {point}
                    </TagLabel>
                </Tag>
            </Flex>
        </Tooltip>
    );
};

const Avatar = ({ source, lastupdate, width, position, top }) => {
    return (
        <Image
            className={'drop-shadow'}
            position={position}
            width={width}
            top={top}
            objectFit="contain"
            src={source + '?lastmod=' + lastupdate}
        />
    );
};

const ActionButton = ({ children, onBtnClick, setReload, successMsg, abIsDisabled, delay, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

    return (
        <Button
            mx={2}
            size={'md'}
            isLoading={isLoading}
            isDisabled={isLoading || abIsDisabled}
            loadingText="Waiting for blockchain"
            onClick={() => {
                setIsLoading(true);
                onBtnClick()
                    .then(() => {
                        setTimeout(() => {
                            setReload((prevReload) => prevReload + 1);
                            toast({
                                title: 'Done!',
                                description: successMsg,
                                status: 'success',
                                duration: 10000,
                                isClosable: true,
                            });
                            setIsLoading(false);
                        }, delay);
                    })
                    .catch((e) => {
                        toast({
                            title: 'An error occurred.',
                            description: Consts.ExtractErrorString(e),
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                        });
                        setIsLoading(false);
                    });
            }}
            {...props}
        >
            {children}
        </Button>
    );
};

const Headline = ({
    obj,
    ethAccount,
    ethClaim,
    ethSell,
    ethCancelSell,
    ethClaimCost,
    ethLevelUp,
    ethLevelUpCost,
    ethBuy,
    setReload,
    ethPrice,
}) => {
    const formatPrice = (val) => val + ethers.constants.EtherSymbol;
    const parsePrice = (val) => val.replace('/' + ethers.constants.EtherSymbol + '$/', '');

    const [price, setPrice] = React.useState('1');

    return (
        <Box>
            <Heading my="20px" fontFamily="Cinzel Decorative, cursive" size="2xl">
                {obj['firstname'] + ' ' + obj['lastname']}
            </Heading>
            <Heading fontFamily="Vollkorn, Georgia, serif" size="lg">
                {'Level ' + obj['level'] + ', Score: ' + obj['attribtotal']}
            </Heading>
            {obj['owner'] === null ? (
                <ActionButton
                    colorScheme={'green'}
                    setReload={setReload}
                    successMsg="Hero claimed"
                    onBtnClick={() => {
                        return ethClaim(obj['tokenid']);
                    }}
                    delay={1000}
                >
                    {ethClaimCost ? 'Claim ' + Consts.GetPriceString(ethClaimCost) : 'Claim'}
                </ActionButton>
            ) : (
                <>
                    <Box>
                        Owner: <Consts.Address ethAccount={ethAccount} isLink address={obj['owner']} />
                    </Box>
                    {Consts.IsSameAddress(obj['owner'], ethAccount) ? (
                        <Flex flexDirection="row" alignItems="center" flexWrap="wrap">
                            {obj['price'] > 0 ? (
                                <Flex alignItems="center" mt="10px">
                                    {'Selling for ' + Consts.GetPriceString(obj['price'], ethPrice)}
                                    <ActionButton
                                        colorScheme={'red'}
                                        setReload={setReload}
                                        successMsg="Sale cancelled"
                                        onBtnClick={() => {
                                            return ethCancelSell(obj['tokenid']);
                                        }}
                                        delay={1000}
                                    >
                                        Cancel Sale
                                    </ActionButton>
                                </Flex>
                            ) : (
                                <Flex alignItems="center" mt="10px">
                                    <NumberInput
                                        maxW="100px"
                                        step={0.1}
                                        onChange={(v) => setPrice(parsePrice(v))}
                                        value={formatPrice(price)}
                                        pattern={'[0-9]*(.[0-9]+)?' + ethers.constants.EtherSymbol}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                    <ActionButton
                                        colorScheme={'yellow'}
                                        setReload={setReload}
                                        successMsg="Sale initiated"
                                        onBtnClick={() => {
                                            return ethSell(obj['tokenid'], ethers.utils.parseEther(price));
                                        }}
                                        delay={1000}
                                    >
                                        Sell
                                    </ActionButton>
                                </Flex>
                            )}
                            {obj['owner'] !== null ? (
                                Consts.IsSameAddress(obj['owner'], ethAccount) ? (
                                    obj['canLevelUp'] === -1 ? null : (
                                        <Box mt="10px">
                                            <ActionButton
                                                colorScheme={'green'}
                                                setReload={setReload}
                                                successMsg="Level up. Avatar may take a while to update"
                                                onBtnClick={() => {
                                                    return ethLevelUp(obj['tokenid'], ethLevelUpCost);
                                                }}
                                                abIsDisabled={obj['canLevelUp'] !== 0}
                                                delay={6000}
                                            >
                                                {obj['canLevelUp'] === 0
                                                    ? 'Level ' +
                                                      (obj['level'] + 1).toString() +
                                                      ' for ' +
                                                      Consts.GetPriceString(ethLevelUpCost)
                                                    : 'Level up in ' + obj['canLevelUp'] + ' blocks'}
                                            </ActionButton>
                                            <Link href="/faq#leveling">What's this?</Link>
                                        </Box>
                                    )
                                ) : null
                            ) : null}
                        </Flex>
                    ) : obj['price'] > 0 ? (
                        <ActionButton
                            colorScheme={'blue'}
                            setReload={setReload}
                            successMsg="Sale complete"
                            onBtnClick={() => {
                                return ethBuy(obj['tokenid'], obj['price'].toString());
                            }}
                            delay={1000}
                        >
                            {'Buy ' + Consts.GetPriceString(obj['price'])}
                        </ActionButton>
                    ) : null}
                </>
            )}
        </Box>
    );
};

const Details = ({ obj, display }) => {
    return (
        <Box fontFamily="Rosario, Georgia, serif" display={display}>
            <Flex flexWrap="wrap">
                <Attrib
                    color="red"
                    point={obj['attribstr']}
                    icon={faFistRaised}
                    name={'Strength'}
                    desc={obj['attribstrtext']}
                />
                <Attrib
                    color="green"
                    point={obj['attribdex']}
                    icon={faRunning}
                    name={'Dexterity'}
                    desc={obj['attribdextext']}
                />
                <Attrib
                    color="yellow"
                    point={obj['attribcon']}
                    icon={faHeartbeat}
                    name={'Constitution'}
                    desc={obj['attribcontext']}
                />
                <Attrib
                    color="blue"
                    point={obj['attribint']}
                    icon={faEye}
                    name={'Intellect'}
                    desc={obj['attribinttext']}
                />
                <Attrib
                    color="gray"
                    point={obj['attribwis']}
                    icon={faBook}
                    name={'Wisdom'}
                    desc={obj['attribwistext']}
                />
                <Attrib
                    color="purple"
                    point={obj['attribcha']}
                    icon={faTheaterMasks}
                    name={'Charisma'}
                    desc={obj['attribchatext']}
                />
            </Flex>
            <Feature title="Sign" subtitles={[obj['sign']]} descs={[obj['signtext']]} freqs={[obj['signfreq']]} />
            <Feature
                title="Alignment"
                subtitles={[obj['alignment']]}
                descs={[obj['alignmenttext']]}
                freqs={[obj['alignmentfreq']]}
            />
            <Feature title="Traits" subtitles={obj['traits']} descs={obj['traitstext']} freqs={obj['traitsfreq']} />
        </Box>
    );
};

const chainToDB = (trades) => {
    let entries = [];

    trades[0].forEach((logEntry, index) => {
        entries.push({
            blockNumber: logEntry.blockNumber,
            action: 'claim',
            fromaddr: '',
            toaddr: logEntry.args[1],
            price: 0,
            tx: logEntry.transactionHash,
        });
    });

    trades[1].forEach((logEntry, index) => {
        entries.push({
            blockNumber: logEntry.blockNumber,
            action: 'for_sale',
            fromaddr: logEntry.args[1],
            toaddr: '',
            price: logEntry.args[2],
            tx: logEntry.transactionHash,
        });
    });

    trades[2].forEach((logEntry, index) => {
        entries.push({
            blockNumber: logEntry.blockNumber,
            action: 'cancel_for_sale',
            fromaddr: logEntry.args[1],
            toaddr: '',
            price: 0,
            tx: logEntry.transactionHash,
        });
    });

    trades[3].forEach((logEntry, index) => {
        entries.push({
            blockNumber: logEntry.blockNumber,
            action: 'sale',
            fromaddr: logEntry.args[1],
            toaddr: logEntry.args[2],
            price: logEntry.args[3],
            tx: logEntry.transactionHash,
        });
    });

    trades[4].forEach((logEntry, index) => {
        if (logEntry.args[0].replaceAll('0', '').replaceAll('x', '') !== '') {
            entries.push({
                blockNumber: logEntry.blockNumber,
                action: 'transfer',
                fromaddr: logEntry.args[0],
                toaddr: logEntry.args[1],
                tx: logEntry.transactionHash,
            });
        }
    });

    trades[5].forEach((logEntry, index) => {
        entries.push({
            blockNumber: logEntry.blockNumber,
            action: 'levelup',
            fromaddr: '',
            toaddr: '',
            price: logEntry.args[1],
            tx: logEntry.transactionHash,
        });
    });

    entries.sort((a, b) => (a.blockNumber < b.blockNumber ? 1 : -1));

    return entries;
};

function Char(props) {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [obj, setObj] = useState(null);
    // TODO state/error for trades
    const [trades, setTrades] = useState(null);

    const { id } = useParams();

    const [reload, setReload] = useState(false);
    const toast = useToast();

    const montageUrl = useColorModeValue(Consts.MontageDark, Consts.MontageLight);

    useEffect(() => {
        document.title = Consts.WebsiteName;
    }, []);

    useEffect(() => {
        if (!props.triedEthReadContract) {
            return;
        }

        fetch('https://' + Consts.APIURL + '/char/' + id, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }

                throw new Error(res.statusText);
            })
            // .then((x) => new Promise((resolve) => setTimeout(() => resolve(x), 3000)))
            // .then((x) => new Promise((resolve, reject) => setTimeout(() => reject('something went wrong'), 2000)))
            .then((result) => {
                document.title = result['firstname'] + ' ' + result['lastname'] + ' | ' + Consts.WebsiteName;

                result['canLevelUp'] = 0;

                if (props.ethReadContract) {
                    const claimable = props.ethReadContract.tokenClaimable(id);
                    const owner = props.ethReadContract.ownerOf(id);
                    const salePrice = props.ethReadContract.salePrice(id);
                    const canLevelUp = props.ethReadContract.tokenCanLevelUp(id);
                    const level = props.ethReadContract.getTokenLevel(id);
                    Promise.all([claimable, owner, salePrice, canLevelUp, level])
                        .then(([_claimable, _owner, _salePrice, _canLevelUp, _level]) => {
                            result['claimed'] = !_claimable;
                            result['owner'] = _owner;
                            result['price'] = _salePrice;
                            result['canLevelUp'] = _canLevelUp.toNumber();
                            result['level'] = _level.toNumber();
                        })
                        .catch(() => {})
                        .finally(() => {
                            setObj(result);
                            setLoaded(true);
                        });
                } else {
                    setObj(result);
                    setLoaded(true);
                }
            })
            .catch((e) => {
                toast({
                    title: 'Failed to retrieve hero info',
                    description: Consts.ExtractErrorString(e),
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                setError(true);
            });

        if (props.ethReadContract) {
            const claimed = props.ethReadContract.filters.Claimed(parseInt(id), null);
            const forSale = props.ethReadContract.filters.ForSale(parseInt(id), null, null);
            const cancelForSale = props.ethReadContract.filters.CancelForSale(parseInt(id), null);
            const sale = props.ethReadContract.filters.Sale(parseInt(id), null, null, null);
            const transfer = props.ethReadContract.filters.Transfer(null, null, parseInt(id));
            const levelup = props.ethReadContract.filters.LevelUp(parseInt(id), null);
            Promise.all(
                [claimed, forSale, cancelForSale, sale, transfer, levelup].map((x) =>
                    props.ethReadContract.queryFilter(x)
                )
            )
                .then((values) => {
                    setTrades(chainToDB(values));
                })
                .catch((e) => {
                    toast({
                        title: "Can't retrieve transactions",
                        description: Consts.ExtractErrorString(e),
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                });
        } else {
            fetch('https://' + Consts.APIURL + '/transactions/' + id, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }

                    throw new Error(res.statusText);
                })
                .then((result) => {
                    setTrades(result['transactions']);
                })
                .catch((e) => {
                    toast({
                        title: "Can't retrieve transactions",
                        description: Consts.ExtractErrorString(e),
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                });
        }
    }, [toast, id, props.triedEthReadContract, props.ethReadContract, reload]);

    return (
        <Box>
            <Flex
                h={'360px'}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                backgroundImage={montageUrl}
            ></Flex>
            <Flex
                w={['90%', '576px', '732px', '992px', '1220px']}
                m="auto"
                position={['relative', 'relative', 'relative', 'static', 'static']}
                top={['-200px', '-90px', '-160px', 'none', 'none']}
            >
                {error ? (
                    <Flex w="100%" my="100px" flexDirection="column" justifyContent="center" alignItems="center">
                        Something went wrong... Please refresh the page to retry.
                    </Flex>
                ) : loaded ? (
                    <Box>
                        <Flex
                            flexDirection={['column', 'row', 'row', 'row', 'row']}
                            justifyContent="space-around"
                            alignItems={['center', 'flex-end', 'flex-end', 'flex-start', 'flex-start']}
                        >
                            <Avatar
                                source={obj['image_large']}
                                lastupdate={obj['lastupdate']}
                                width={['60%', '40%', '40%', '400px', '400px']}
                                position={['static', 'static', 'static', 'relative', 'relative']}
                                top={['none', 'none', 'none', '-150px', '-150px']}
                            />
                            <Box w={['100%', '50%', '50%', '50%', '50%']}>
                                <Headline
                                    obj={obj}
                                    ethAccount={props.ethAccount}
                                    ethClaim={props.ethClaim}
                                    ethSell={props.ethSell}
                                    ethCancelSell={props.ethCancelSell}
                                    ethClaimCost={props.ethClaimCost}
                                    ethLevelUpCost={props.ethLevelUpCost}
                                    ethLevelUp={props.ethLevelUp}
                                    ethBuy={props.ethBuy}
                                    setReload={setReload}
                                    ethPrice={props.ethPrice}
                                />
                                <Details obj={obj} display={['none', 'none', 'none', 'block', 'block']} />
                            </Box>
                        </Flex>
                        <Details obj={obj} display={['block', 'block', 'block', 'none', 'none']} />
                        <Divider my={'30px'} sx={{ borderColor: 'grey' }} />
                        <Box fontFamily="Rosario, Georgia, serif">
                            <Heading fontFamily="Vollkorn, Georgia, serif" size="lg" mb={2}>
                                Transactions
                            </Heading>
                            {trades ? Consts.BuildTransactions(trades, props.ethAccount, props.ethPrice, false) : null}
                        </Box>
                    </Box>
                ) : (
                    <Flex w="100%" my="100px" flexDirection="column" justifyContent="center" alignItems="center">
                        Loading hero...
                        <Progress w="200px" size="lg" hasStripe isAnimated value={100} />
                    </Flex>
                )}
            </Flex>
        </Box>
    );
}

export default Char;
