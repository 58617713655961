import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Heading,
    Link,
    useColorMode,
    useColorModeValue,
    Button,
    Flex,
    Box,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    Center,
    Text,
    useToast,
} from '@chakra-ui/react';
import { ethers } from 'ethers';
import FilteredPaginator from './FilteredPaginator.js';
import * as Consts from './Consts.js';

const MinWStat = ({ children }) => {
    return <Stat minW="200px">{children}</Stat>;
};

const fetchChars = (endpoint, setItems, setSigns, setAlignments, setNumOnSale, setNumClaimable, setIsLoaded, toast) => {
    fetch(endpoint, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
        .then((res) => {
            if (res.ok) {
                return res.json();
            }

            throw new Error(res.statusText);
        })
        .then((result) => {
            let signsSet = new Set();
            let alignmentsSet = new Set();
            let onSale = 0;
            let claimable = 0;
            result['characters'].forEach((value) => {
                signsSet.add(value['sign']);
                alignmentsSet.add(value['alignment']);
                value['searchable'] = [
                    value['firstname'],
                    value['lastname'],
                    value['alignment'],
                    value['sign'],
                    'level ' + value['level'],
                ].join(' ');
                if (value['price'] > 0) {
                    onSale++;
                }
                if (!value['owner'] > 0) {
                    claimable++;
                }
            });
            setItems(result['characters']);
            setSigns(Array.from(signsSet).sort());
            setAlignments(Array.from(alignmentsSet).sort());
            setNumOnSale(onSale);
            setNumClaimable(claimable);

            setIsLoaded(true);
        })
        .catch((e) => {
            toast({
                title: 'Something went wrong',
                description: Consts.ExtractErrorString(e),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        });
};

function Main(props) {
    const { colorMode } = useColorMode();
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [signs, setSigns] = useState([]);
    const [alignments, setAlignments] = useState([]);
    const [numOnSale, setNumOnSale] = useState(0);
    const [numClaimable, setNumClaimable] = useState(0);
    const [addressDisplayed, setAddressDisplayed] = useState('');
    const [pageType, setPageType] = useState(0);
    const [isConnecting, setIsConnecting] = useState(false);
    const [trades, setTrades] = useState(null);
    const toast = useToast();

    const { urlaccount } = useParams();

    const montageUrl = useColorModeValue(Consts.MontageDark, Consts.MontageLight);

    useEffect(() => {
        // done only the first time
        document.title = props.isAccount
            ? 'Heroes Collection | ' + Consts.WebsiteName
            : Consts.WebsiteName + ' | ' + Consts.WebsiteDesc;

        // done if it's overall page
        if (!props.isAccount || (urlaccount && !ethers.utils.isAddress(urlaccount))) {
            fetchChars(
                'https://' + Consts.APIURL + '/chars',
                setItems,
                setSigns,
                setAlignments,
                setNumOnSale,
                setNumClaimable,
                setIsLoaded,
                toast
            );
            setPageType(0);
        }
    }, [urlaccount, props.isAccount, toast]);

    useEffect(() => {
        if (!props.isAccount || (urlaccount && !ethers.utils.isAddress(urlaccount))) {
            return;
        }

        let acc;
        if (urlaccount) {
            acc = urlaccount;
            if (urlaccount === props.ethAccount) {
                setPageType(1);
            } else {
                setPageType(2);
            }
        } else if (props.ethAccount) {
            acc = props.ethAccount;
            setPageType(1);
        } else {
            setPageType(1);
            return;
        }

        setAddressDisplayed(acc);

        fetchChars(
            'https://' + Consts.APIURL + '/chars/' + acc,
            setItems,
            setSigns,
            setAlignments,
            setNumOnSale,
            setNumClaimable,
            setIsLoaded,
            toast
        );
    }, [urlaccount, props.ethAccount, props.isAccount, toast]);

    useEffect(() => {
        fetch('https://' + Consts.APIURL + '/recent', {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }

                throw new Error(res.statusText);
            })
            .then((result) => {
                setTrades(result['transactions']);
            })
            .catch(() => {});
    }, []);

    return (
        <Box>
            <Flex
                bg={Consts.bgColorReversed[colorMode]}
                py={'80px'}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                backgroundImage={montageUrl}
            >
                <Consts.FgText
                    reversedColor
                    as="b"
                    colorMode={colorMode}
                    fontFamily="Cinzel Decorative, cursive"
                    fontSize={['6xl', '8xl']}
                >
                    {pageType !== 0 ? (pageType === 2 ? 'Heroes' : 'My Collection') : 'Pick Your Heroes!'}
                </Consts.FgText>
                <Consts.FgText reversedColor fontSize="4xl" colorMode={colorMode}>
                    {pageType !== 0 ? (
                        pageType === 2 ? (
                            <>
                                <Consts.Address address={urlaccount} canCopy />
                                's Collection
                            </>
                        ) : (
                            <Consts.Address address={props.ethAccount} canCopy />
                        )
                    ) : (
                        'Collectable Card Game on the Blockchain'
                    )}
                </Consts.FgText>
                {pageType === 0 ? (
                    <Flex flexDirection="row" mt="20px">
                        <Button as="a" href="#updates" colorScheme="blue" mx="20px">
                            Get Updates
                        </Button>
                        {props.ethAccount ? null : (
                            <Consts.StartButton mx="20px" ethConnect={props.ethConnect}>
                                Start Collecting!
                            </Consts.StartButton>
                        )}
                    </Flex>
                ) : null}
            </Flex>
            <Flex
                bg={Consts.bgColor[colorMode]}
                id="main-container"
                mt="20px"
                maxW={['100%', '576px', '732px', '992px', '1220px']}
                mx="auto"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                {pageType !== 0 ? (
                    props.ethAccount || pageType !== 1 ? (
                        <>
                            <Flex
                                w="90%"
                                id="stats"
                                flexDirection="row"
                                justifyContent="space-around"
                                alignItems="center"
                                flexWrap="wrap"
                            >
                                <MinWStat>
                                    <StatLabel>Heroes</StatLabel>
                                    <StatNumber>{items.length}</StatNumber>
                                    <StatHelpText>
                                        Number of heroes owned by{' '}
                                        <Consts.Address
                                            address={addressDisplayed}
                                            ethAccount={props.ethAccount}
                                            canCopy
                                        />
                                    </StatHelpText>
                                </MinWStat>
                                <MinWStat>
                                    <StatLabel>Selling</StatLabel>
                                    <StatNumber>{numOnSale}</StatNumber>
                                    <StatHelpText>
                                        Heroes for sale by{' '}
                                        <Consts.Address
                                            address={addressDisplayed}
                                            ethAccount={props.ethAccount}
                                            canCopy
                                        />
                                    </StatHelpText>
                                </MinWStat>
                                <MinWStat>
                                    <StatLabel>Signs</StatLabel>
                                    <StatNumber>{signs.length + ' of 88'}</StatNumber>
                                    <StatHelpText>
                                        Unique signs owned by{' '}
                                        <Consts.Address
                                            address={addressDisplayed}
                                            ethAccount={props.ethAccount}
                                            canCopy
                                        />
                                    </StatHelpText>
                                </MinWStat>
                                <MinWStat>
                                    <StatLabel>Alignments</StatLabel>
                                    <StatNumber>{alignments.length + ' of 9'}</StatNumber>
                                    <StatHelpText>
                                        Unique alignments owned by{' '}
                                        <Consts.Address
                                            address={addressDisplayed}
                                            ethAccount={props.ethAccount}
                                            canCopy
                                        />
                                    </StatHelpText>
                                </MinWStat>
                            </Flex>
                            <FilteredPaginator
                                ethAccount={pageType === 2 ? urlaccount : props.ethAccount}
                                isLoaded={isLoaded}
                                items={items}
                                signs={signs}
                                alignments={alignments}
                                accentBg={pageType === 2 ? 'Gray' : 'tomato'}
                            />
                        </>
                    ) : (
                        <>
                            <Center>
                                <Box>
                                    <Text fontSize="lg">Connect an Ethereum wallet to see your heroes</Text>
                                </Box>
                            </Center>
                            <Center>
                                <Box>
                                    <Button
                                        isLoading={isConnecting}
                                        loadingText="Connecting"
                                        colorScheme="blue"
                                        onClick={() => {
                                            setIsConnecting(true);
                                            props
                                                .ethConnect()
                                                .catch((e) => {
                                                    toast({
                                                        title: 'An error occurred.',
                                                        description: Consts.ExtractErrorString(e),
                                                        status: 'error',
                                                        duration: 5000,
                                                        isClosable: true,
                                                    });
                                                })
                                                .finally(() => {
                                                    setIsConnecting(false);
                                                });
                                        }}
                                    >
                                        Connect Wallet
                                    </Button>
                                </Box>
                            </Center>
                        </>
                    )
                ) : (
                    <>
                        <Flex
                            w="90%"
                            id="stats"
                            flexDirection="row"
                            justifyContent="space-around"
                            alignItems="center"
                            flexWrap="wrap"
                        >
                            <MinWStat>
                                <StatLabel>Heroes</StatLabel>
                                <StatNumber>{items.length}</StatNumber>
                                <StatHelpText>Total number of heroes</StatHelpText>
                            </MinWStat>
                            <MinWStat>
                                <StatLabel>Selling</StatLabel>
                                <StatNumber>{numOnSale}</StatNumber>
                                <StatHelpText>Heroes for sale</StatHelpText>
                            </MinWStat>
                            <MinWStat>
                                <StatLabel>Claimable</StatLabel>
                                <StatNumber>{numClaimable}</StatNumber>
                                <StatHelpText>Number of heroes left to be claimed</StatHelpText>
                            </MinWStat>
                            <MinWStat>
                                <StatLabel>Claim Fee</StatLabel>
                                <StatNumber>
                                    {props.ethClaimCost ? Consts.GetPriceString(props.ethClaimCost) : 0}
                                </StatNumber>
                                <StatHelpText>
                                    Batch 2 (<Link href="/roadmap">See Roadmap</Link>)
                                </StatHelpText>
                            </MinWStat>
                            {/*
                            <MinWStat>
                                <StatLabel>Volume</StatLabel>
                                <StatNumber>10000</StatNumber>
                                <StatHelpText>Trade volume in the last 24 hours</StatHelpText>
                            </MinWStat>
                            */}
                        </Flex>
                        <FilteredPaginator
                            isLoaded={isLoaded}
                            items={items}
                            signs={signs}
                            alignments={alignments}
                            accentBg={colorMode === 'light' ? 'blue.400' : 'blue.500'}
                        />
                        <Flex flexDirection="column" my="40px" id="recent">
                            <Box fontFamily="Rosario, Georgia, serif">
                                <Heading fontFamily="Vollkorn, Georgia, serif" size="lg" mb={2}>
                                    Recent Transactions
                                </Heading>
                                {trades
                                    ? Consts.BuildTransactions(trades, props.ethAccount, props.ethPrice, true)
                                    : null}
                            </Box>
                        </Flex>
                        <Consts.SubscribeForm id="updates" my="40px" />
                    </>
                )}
            </Flex>
        </Box>
    );
}

export default Main;
