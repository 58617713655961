import React, { useEffect } from 'react';
import { Link, List, ListItem, ListIcon, Box, Center, Heading, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCheck, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import * as Consts from './Consts.js';

const check = () => <FontAwesomeIcon color="gray" icon={faCheck} />;
const arrow = () => <FontAwesomeIcon color="tomato" icon={faArrowRight} />;
const clock = () => <FontAwesomeIcon color="gray" icon={faClock} />;

const Roadmap = (props) => {
    useEffect(() => {
        document.title = 'Roadmap & Release Schedule | ' + Consts.WebsiteName;
    }, []);

    return (
        <Consts.DocumentPage>
            <Center>
                <Heading my="30px" size="2xl">
                    Roadmap and Release Schedule
                </Heading>
            </Center>
            <Text>
                Each phase starts when the previous phase is complete. A batch is considered complete when all Heroes in
                the batch are claimed.
            </Text>
            <List fontFamily="Noto Sans, Verdana, san-serif" spacing={10} my="20px">
                <ListItem color="gray.400">
                    <ListIcon as={check} />
                    <Text as="b" ml="10px">
                        Initial launch (completed)
                    </Text>
                </ListItem>
                <ListItem color="gray.400">
                    <ListIcon as={check} />
                    <Text as="b" ml="10px">
                        Batch 0 Release (completed)
                    </Text>
                    <Box>
                        <Text as="sub">50 Heroes, {Consts.GetPriceString(0)} claim fee.</Text>
                    </Box>
                </ListItem>
                <ListItem color="gray.400">
                    <ListIcon as={check} />
                    <Text as="b" ml="10px">
                        Batch 1 Release
                    </Text>
                    <Box>
                        <Text as="sub">50 Heroes, {Consts.GetPriceString(1e16)} claim fee.</Text>
                    </Box>
                </ListItem>
                <ListItem color="gray.400">
                    <ListIcon as={check} />
                    <Text as="b" ml="10px">
                        Leveling System
                    </Text>
                    <Box>
                        <Text as="sub">
                            Heroes can level up and improve their score.{' '}
                            <Link as="b" color="tomato" href="/faq#leveling">
                                Learn more
                            </Link>
                        </Text>
                    </Box>
                </ListItem>
                <ListItem>
                    <ListIcon as={arrow} />
                    <Text as="b" ml="10px">
                        Batch 2 Release
                    </Text>
                    <Box>
                        <Text as="sub">100 Heroes, {Consts.GetPriceString(2e16)} claim fee.</Text>
                    </Box>
                </ListItem>
                <ListItem>
                    <ListIcon as={clock} />
                    <Text as="b" ml="10px">
                        Player Bidding
                    </Text>
                    <Box>
                        <Text as="sub">Players can place bids on Heroes.</Text>
                    </Box>
                </ListItem>
                <ListItem>
                    <ListIcon as={clock} />
                    <Text as="b" ml="10px">
                        Batch 3 Release
                    </Text>
                </ListItem>
                <ListItem>
                    <ListIcon as={clock} />
                    <Text as="b" ml="10px">
                        Animated Cards
                    </Text>
                    <Box>
                        <Text as="sub">Certain Hero cards will be animated.</Text>
                    </Box>
                </ListItem>
                <ListItem>
                    <ListIcon as={clock} />
                    <Text as="b" ml="10px">
                        Batch 4 Release
                    </Text>
                </ListItem>
                <ListItem>
                    <ListIcon as={clock} />
                    <Text as="b" ml="10px">
                        Learn Skills
                    </Text>
                    <Box>
                        <Text as="sub">Heroes can learn skills. Players can trade skill books.</Text>
                    </Box>
                </ListItem>
                <ListItem>
                    <ListIcon as={clock} />
                    <Text as="b" ml="10px">
                        Batch 5 Release
                    </Text>
                </ListItem>
                <ListItem>
                    <ListIcon as={clock} />
                    <Text as="b" ml="10px">
                        Apprenticeships
                    </Text>
                </ListItem>
            </List>
        </Consts.DocumentPage>
    );
};

export default Roadmap;
