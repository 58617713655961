import React, { useState } from 'react';
import {
    Flex,
    Heading,
    FormControl,
    FormLabel,
    FormHelperText,
    Input,
    useToast,
    Tag,
    Button,
    useClipboard,
    Link,
    Center,
    Tooltip,
    Text,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    Th,
} from '@chakra-ui/react';
import { ethers } from 'ethers';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

export const bgColor = { light: '#d3dae3', dark: '#2d2d2d' };
export const bgColorReversed = { light: bgColor.dark, dark: bgColor.light };
export const fgColor = { light: '#242729', dark: '#e7e8eb' };
export const fgColorReversed = { light: fgColor.dark, dark: fgColor.light };

export const FgText = ({ children, colorMode, reversedColor, ...props }) => {
    return reversedColor ? (
        <Text color={fgColorReversed[colorMode]} {...props}>
            {children}
        </Text>
    ) : (
        <Text color={fgColor[colorMode]} {...props}>
            {children}
        </Text>
    );
};

export const EthTransaction = ({ tx }) => {
    return (
        <Tooltip label={'Click to open transaction ' + tx} hasArrow placement="bottom">
            <Link isExternal href={EtherScanURL + '/tx/' + tx} fontFamily="monospace">
                {tx.substring(0, 5) + '..' + tx.substring(tx.length - 3, tx.length)}
            </Link>
        </Tooltip>
    );
};

export const GetShortenedAddress = (addr) => {
    return addr.substring(0, 5) + '..' + addr.substring(addr.length - 3, addr.length);
};

export const Address = ({ address, ethAccount, isLink, canCopy }) => {
    const { hasCopied, onCopy } = useClipboard(address);

    return (
        <Tooltip
            label={canCopy ? address + (hasCopied ? ' | Copied!' : ' | Click to copy') : address}
            hasArrow
            placement="bottom"
        >
            <Box
                as={isLink ? 'a' : 'span'}
                fontFamily="monospace"
                cursor={isLink || canCopy ? 'pointer' : 'default'}
                href={isLink ? '/account/' + address : null}
                onClick={canCopy ? onCopy : null}
            >
                {address
                    ? ethAccount && ethAccount.toLowerCase() === address.toLowerCase()
                        ? 'You'
                        : GetShortenedAddress(address)
                    : ''}
            </Box>
        </Tooltip>
    );
};

export const IsSameAddress = (addr0, addr1) => {
    if (!addr0 || !addr1) {
        return false;
    }

    if (addr0.startsWith('0x')) {
        addr0 = addr0.substring(2);
    }

    if (addr1.startsWith('0x')) {
        addr1 = addr1.substring(2);
    }

    return addr0.toLowerCase() === addr1.toLowerCase();
};

export const GetPriceString = (price, ethPrice = 0) => {
    if (price) {
        const eth = ethers.utils.formatEther(price.toString());
        if (ethPrice) {
            return eth + ethers.constants.EtherSymbol + ' ($' + ((price / 1e18) * ethPrice).toFixed(2) + ')';
        } else {
            return eth + ethers.constants.EtherSymbol;
        }
    } else {
        return '0';
    }
};

export const WebsiteURL = 'etheroes.io';
export const APIURL = 'api.etheroes.io';
export const WebsiteName = 'EtHeroes';
export const WebsiteDesc = 'Collectable Card Game on the Blockchain';
const mainnet = true;
export const ContractAddress = mainnet
    ? '0xc2d809eCBAD42b13d7D431adbF2aBD7039Cf5977'
    : '0x82bF6257F8b55C6B22Ef030a99dc37166B207AC3';
export const NetworkID = mainnet ? 1 : 3;
export const EtherScanURL = 'https://etherscan.io';

export const DocumentPage = ({ children }) => {
    return (
        <Center>
            <Box fontFamily="Rosario, Georgia, serif" maxW={['90%', '576px', '732px']}>
                {children}
            </Box>
        </Center>
    );
};

export const ThirdPartyWalletOnClick = () => {
    window.open(isMobile ? 'https://wallet.coinbase.com' : 'https://metamask.io/download.html', '_blank');
};

export const MontageLight = "url('/images/montage-light.jpg')";
export const MontageDark = "url('/images/montage-dark.jpg')";

export const ExtractErrorString = (resp) => {
    if (!resp) {
        return 'Error';
    }

    if (resp.error) {
        if (resp.error.message) {
            return resp.error.message;
        } else {
            return resp.error.toString();
        }
    } else if (resp.message) {
        return resp.message;
    } else {
        return resp.toString();
    }
};

export const CLink = ({ children, ...props }) => (
    <Link color="tomato" {...props}>
        <b>{children}</b>
    </Link>
);

export const ThirdPartyWallet = isMobile ? 'Coinbase Wallet' : 'MetaMask';

export const StartButton = ({ ethConnect, children, ...props }) => {
    const [isConnecting, setIsConnecting] = useState(false);
    const toast = useToast();

    return (
        <Button
            colorScheme="blue"
            isLoading={isConnecting}
            loadingText="Connecting"
            onClick={() => {
                setIsConnecting(true);
                ethConnect()
                    .catch((e) => {
                        toast({
                            title: 'An error occurred.',
                            description: ExtractErrorString(e),
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                        });
                    })
                    .finally(() => {
                        setIsConnecting(false);
                    });
            }}
            {...props}
        >
            {children}
        </Button>
    );
};

export const BuildTransactions = (trades, ethAccount, ethPrice, hasAvatar) => {
    const inner = trades.map((x, index) => {
        switch (x.action) {
            case 'claim':
                return (
                    <Tr key={index}>
                        {hasAvatar ? (
                            <Td display="table-cell">
                                <Link href={'/char/' + x.tokenid}>{'Hero #' + x.tokenid}</Link>
                            </Td>
                        ) : (
                            <Td display={['none', 'none', 'table-cell']}>
                                <FontAwesomeIcon color="green" icon={faCheck} />
                            </Td>
                        )}
                        <Td display="table-cell">
                            <Tag variant="solid" colorScheme="blue">
                                Claimed
                            </Tag>
                        </Td>
                        <Td display="table-cell" />
                        <Td display={['none', 'none', 'table-cell']} />
                        <Td display={hasAvatar ? ['none', 'none', 'table-cell'] : 'table-cell'}>
                            <Address ethAccount={ethAccount} isLink address={x.toaddr} />
                        </Td>
                        <Td display={['none', 'none', 'table-cell']}>
                            <EthTransaction tx={x.tx} />
                        </Td>
                    </Tr>
                );
            case 'for_sale':
                return (
                    <Tr key={index}>
                        {hasAvatar ? (
                            <Td display="table-cell">
                                <Link href={'/char/' + x.tokenid}>{'Hero #' + x.tokenid}</Link>
                            </Td>
                        ) : (
                            <Td display={['none', 'none', 'table-cell']}>
                                <FontAwesomeIcon color="orange" icon={faClock} />
                            </Td>
                        )}
                        <Td display="table-cell">
                            <Tag variant="solid" colorScheme="yellow">
                                Offered for sale
                            </Tag>
                        </Td>
                        <Td display="table-cell">{GetPriceString(x.price, ethPrice)}</Td>
                        <Td display={['none', 'none', 'table-cell']}>
                            <Address ethAccount={ethAccount} isLink address={x.fromaddr} />
                        </Td>
                        <Td display={hasAvatar ? ['none', 'none', 'table-cell'] : 'table-cell'}></Td>
                        <Td display={['none', 'none', 'table-cell']}>
                            <EthTransaction tx={x.tx} />
                        </Td>
                    </Tr>
                );
            case 'cancel_for_sale':
                return (
                    <Tr key={index}>
                        {hasAvatar ? (
                            <Td display="table-cell">
                                <Link href={'/char/' + x.tokenid}>{'Hero #' + x.tokenid}</Link>
                            </Td>
                        ) : (
                            <Td display={['none', 'none', 'table-cell']}>
                                <FontAwesomeIcon color="red" icon={faTimes} />
                            </Td>
                        )}
                        <Td display="table-cell">
                            <Tag variant="solid" colorScheme="red">
                                Sale cancelled
                            </Tag>
                        </Td>
                        <Td display="table-cell" />
                        <Td display={['none', 'none', 'table-cell']}>
                            <Address ethAccount={ethAccount} isLink address={x.fromaddr} />
                        </Td>
                        <Td display={hasAvatar ? ['none', 'none', 'table-cell'] : 'table-cell'}></Td>
                        <Td display={['none', 'none', 'table-cell']}>
                            <EthTransaction tx={x.tx} />
                        </Td>
                    </Tr>
                );
            case 'sale':
                return (
                    <Tr key={index}>
                        {hasAvatar ? (
                            <Td display="table-cell">
                                <Link href={'/char/' + x.tokenid}>{'Hero #' + x.tokenid}</Link>
                            </Td>
                        ) : (
                            <Td display={['none', 'none', 'table-cell']}>
                                <FontAwesomeIcon color="green" icon={faCheck} />
                            </Td>
                        )}
                        <Td display="table-cell">
                            <Tag variant="solid" colorScheme="green">
                                Sale
                            </Tag>
                        </Td>
                        <Td display="table-cell">{GetPriceString(x.price, ethPrice)}</Td>
                        <Td display={['none', 'none', 'table-cell']}>
                            <Address ethAccount={ethAccount} isLink address={x.fromaddr} />
                        </Td>
                        <Td display={hasAvatar ? ['none', 'none', 'table-cell'] : 'table-cell'}>
                            <Address ethAccount={ethAccount} isLink address={x.toaddr} />
                        </Td>
                        <Td display={['none', 'none', 'table-cell']}>
                            <EthTransaction tx={x.tx} />
                        </Td>
                    </Tr>
                );
            case 'transfer':
                return (
                    <Tr key={index}>
                        {hasAvatar ? (
                            <Td display="table-cell">
                                <Link href={'/char/' + x.tokenid}>{'Hero #' + x.tokenid}</Link>
                            </Td>
                        ) : (
                            <Td display={['none', 'none', 'table-cell']}>
                                <FontAwesomeIcon color="green" icon={faCheck} />
                            </Td>
                        )}
                        <Td display="table-cell">
                            <Tag variant="solid" colorScheme="gray">
                                Transfer
                            </Tag>
                        </Td>
                        <Td display="table-cell" />
                        <Td display={['none', 'none', 'table-cell']}>
                            <Address ethAccount={ethAccount} isLink address={x.fromaddr} />
                        </Td>
                        <Td display={hasAvatar ? ['none', 'none', 'table-cell'] : 'table-cell'}>
                            <Address ethAccount={ethAccount} isLink address={x.toaddr} />
                        </Td>
                        <Td display={['none', 'none', 'table-cell']}>
                            <EthTransaction tx={x.tx} />
                        </Td>
                    </Tr>
                );
            case 'levelup':
                return (
                    <Tr key={index}>
                        {hasAvatar ? (
                            <Td display="table-cell">
                                <Link href={'/char/' + x.tokenid}>{'Hero #' + x.tokenid}</Link>
                            </Td>
                        ) : (
                            <Td display={['none', 'none', 'table-cell']}>
                                <FontAwesomeIcon color="green" icon={faCheck} />
                            </Td>
                        )}
                        <Td display="table-cell">
                            <Tag variant="solid" colorScheme="blue">
                                {'Level ' + x.price}
                            </Tag>
                        </Td>
                        <Td display="table-cell" />
                        <Td display="table-cell" />
                        <Td display={hasAvatar ? ['none', 'none', 'table-cell'] : 'table-cell'}></Td>
                        <Td display={['none', 'none', 'table-cell']}>
                            <EthTransaction tx={x.tx} />
                        </Td>
                    </Tr>
                );
            default:
                return '';
        }
    });

    return inner.length === 0 ? (
        'No transactions found.'
    ) : (
        <Table variant="unstyled">
            <Thead>
                <Tr>
                    {hasAvatar ? (
                        <Th display="table-cell">Hero ID</Th>
                    ) : (
                        <Th display={['none', 'none', 'table-cell']} />
                    )}
                    <Th display="table-cell">Action</Th>
                    <Th display="table-cell">Price</Th>
                    <Th display={['none', 'none', 'table-cell']}>From</Th>
                    <Th display={hasAvatar ? ['none', 'none', 'table-cell'] : 'table-cell'}>To</Th>
                    <Th display={['none', 'none', 'table-cell']}>Transaction</Th>
                </Tr>
            </Thead>
            <Tbody fontFamily="monospace">{inner}</Tbody>
        </Table>
    );
};

export const SubscribeForm = ({ children, ...props }) => {
    const [processing, setProcessing] = useState(false);
    const [email, setEmail] = useState('');
    const toast = useToast();

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            return;
        }

        setProcessing(true);

        let request = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };
        fetch('https://' + APIURL + '/subscribe?email=' + email, request)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }

                throw new Error(res.statusText);
            })
            .then(() => {
                form.reset();
                setEmail('');
                toast({
                    title: 'Got it!',
                    description: 'You are now subscribed',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            })
            .catch((e) => {
                toast({
                    title: 'An error occurred.',
                    description: ExtractErrorString(e),
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            })
            .finally(() => {
                setProcessing(false);
            });
    };

    return (
        <Flex flexDirection="column" {...props}>
            <Heading size="xl">Don't miss an update!</Heading>
            <form onSubmit={handleSubmit}>
                <FormControl id="email">
                    <FormLabel>Email address</FormLabel>
                    <Input type="email" onChange={(event) => setEmail(event.currentTarget.value)} />
                    <FormHelperText>We'll never share your email.</FormHelperText>
                </FormControl>
                <Button width="full" mt={4} type="submit" isLoading={processing} isDisabled={email === ''}>
                    Get Updates
                </Button>
            </form>
        </Flex>
    );
};
