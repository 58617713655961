import React, { useEffect, useState } from 'react';
import { useToast, Text, Tag, Box, Flex, Link, useColorMode } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import * as Consts from './Consts.js';

const NavLink = ({ children, ...props }) => (
    <Link textAlign="right" mt={[4, 4, 0, 0, 0]} ml={6} display="block" onClick={props.onClick} href={props.href}>
        {children}
    </Link>
);

const Navbar = (props) => {
    const [show, setShow] = useState(false);
    const [balance, setBalance] = useState('');
    const [levelBalance, setLevelBalance] = useState('');
    const handleToggle = () => setShow(!show);
    const { colorMode, toggleColorMode } = useColorMode();
    const toast = useToast();

    useEffect(() => {
        if (props.ethAccount) {
            fetch('https://' + Consts.APIURL + '/chars/' + props.ethAccount)
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }

                    throw new Error(res.statusText);
                })
                .then((result) => {
                    setBalance(result['characters'].length);
                    const _levelBalance = result['characters'].filter((i) => i['canlevelup']).length;
                    setLevelBalance(_levelBalance);
                    if (_levelBalance > 0) {
                        document.title = '(' + _levelBalance + ') ' + document.title;
                    }
                    if (_levelBalance > 0) {
                        toast({
                            title: 'Level Up!',
                            description: _levelBalance.toString() + ' of your Heroes can be leveled up',
                            status: 'info',
                            duration: 10000,
                            isClosable: true,
                        });
                    }
                })
                .catch((e) => {
                    toast({
                        title: "Can't retrieve account info",
                        description: Consts.ExtractErrorString(e),
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                });
        }
    }, [props.ethAccount, toast]);

    return (
        <Flex
            as="nav"
            bg={Consts.bgColor[colorMode]}
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            w="100%"
            px={5}
            py={4}
        >
            <Flex flexDirection="row" justifyContent="center" alignItems="center">
                <Link href="/" fontFamily="Cinzel Decorative, cursive" fontSize="4xl">
                    {Consts.WebsiteName}
                </Link>
            </Flex>

            <Box display={['block', 'block', 'none', 'none', 'none']} onClick={handleToggle}>
                <svg
                    fill={Consts.fgColor[colorMode]}
                    width="12px"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <title>Menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
            </Box>

            <Box
                display={[show ? 'block' : 'none', show ? 'block' : 'none', 'flex', 'flex', 'flex']}
                width={['full', 'full', 'auto', 'auto', 'auto']}
                alignItems="center"
            >
                <NavLink href="/">
                    <Text as="b">Home</Text>
                </NavLink>
                <NavLink href="/#recent">Transactions</NavLink>
                <NavLink href="/roadmap">Roadmap</NavLink>
                <NavLink href="/faq">FAQ</NavLink>
                <NavLink href={'mailto:hi@' + Consts.WebsiteURL}>Contact</NavLink>
                {props.ethAccount ? (
                    <>
                        <NavLink href="/account">
                            My Heroes
                            <Tag ml="5px" variant="solid" colorScheme="blue" borderRadius="full">
                                {balance}
                            </Tag>
                            {levelBalance > 0 ? (
                                <Tag ml="5px" variant="solid" colorScheme="purple" borderRadius="full">
                                    {levelBalance}
                                </Tag>
                            ) : null}
                        </NavLink>
                    </>
                ) : (
                    <Flex flexDirection="row" justifyContent="flex-end">
                        <Consts.StartButton mt={[4, 4, 0, 0, 0]} ml={6} ethConnect={props.ethConnect}>
                            Connect Wallet
                        </Consts.StartButton>
                    </Flex>
                )}
                <NavLink onClick={toggleColorMode}>
                    <FontAwesomeIcon icon={colorMode === 'light' ? faMoon : faSun} />
                </NavLink>
            </Box>
        </Flex>
    );
};

export default Navbar;
