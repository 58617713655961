import React, { useState } from 'react';
import { Box, LightMode, Tag, Image, Flex, Link } from '@chakra-ui/react';
import Tilt from 'react-parallax-tilt';
import * as Consts from './Consts.js';
import './Card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';

function Card(props) {
    const [isHover, setIsHover] = useState(false);
    // const [btnIsLoading, setBtnIsLoading] = useState(false);

    function onEnter() {
        setIsHover(true);
    }

    function onLeave() {
        setIsHover(false);
    }

    return (
        <Flex flexDirection="column" justifyContent="center">
            <Link href={'/char/' + props.value['tokenid']}>
                <Tilt
                    className="Tilt"
                    scale={1.1}
                    glareEnable={true}
                    tiltAngleYInitial={10}
                    style={{ margin: 20 }}
                    onEnter={onEnter}
                    onLeave={onLeave}
                >
                    <Image
                        className={isHover ? 'drop-shadow-hover' : 'drop-shadow'}
                        width={['35vw', '152px', '204px', '158px', '204px']}
                        height={['58vw', '253px', '340px', '263px', '340px']}
                        objectFit="scale-down"
                        src={
                            'https://' +
                            Consts.WebsiteURL +
                            '/img/chars/' +
                            props.value['tokenid'] +
                            '.png?lastmod=' +
                            props.value['lastupdate']
                        }
                        alt={props.value['firstname'] + ' ' + props.value['lastname']}
                    />
                    <LightMode>
                        {props.value['claimed'] ? null : (
                            <Tag
                                colorScheme="green"
                                size="sm"
                                variant="solid"
                                pos="absolute"
                                bottom={['5px', '5px', '10px', '5px', '10px']}
                                right="50px"
                                borderRadius={'md'}
                            >
                                Claimable!
                            </Tag>
                        )}
                        {props.value['price'] && props.value['price'] > 0 ? (
                            <Tag
                                colorScheme="blue"
                                size="sm"
                                variant="solid"
                                pos="absolute"
                                bottom={['5px', '5px', '10px', '5px', '10px']}
                                right="50px"
                                borderRadius={'md'}
                            >
                                {Consts.GetPriceString(props.value['price'])}
                            </Tag>
                        ) : null}
                        {props.value['canlevelup'] ? (
                            <Box pos="absolute" bottom={['5px', '5px', '10px', '5px', '10px']} left="10px">
                                <FontAwesomeIcon color="purple" icon={faAngleDoubleUp} />
                            </Box>
                        ) : null}
                    </LightMode>
                </Tilt>
            </Link>
        </Flex>
    );
}

export default Card;
