import React, { useState, useEffect } from 'react';
import { Box, Center, Heading, Text, Table, Thead, Tbody, Tr, Td, Th } from '@chakra-ui/react';
import * as Consts from './Consts.js';

const FAQHeading = ({ children, ...props }) => {
    return (
        <Heading mt="50px" mb="20px" fontFamily="Vollkorn, Georgia, serif" size="lg" {...props}>
            {children}
        </Heading>
    );
};

const Paragraph = ({ children, ...props }) => {
    return (
        <Text mb="10px" {...props}>
            {children}
        </Text>
    );
};

const FAQ = () => {
    const [totalChars, setTotalChars] = useState(0);

    useEffect(() => {
        document.title = 'FAQs | ' + Consts.WebsiteName;

        fetch('https://' + Consts.APIURL + '/chars')
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }

                throw new Error(res.statusText);
            })
            .then((result) => {
                setTotalChars(result['characters'].length);
            });
    }, []);

    return (
        <Consts.DocumentPage>
            <Center>
                <Heading my="30px" size="2xl">
                    FAQs
                </Heading>
            </Center>

            <Box>
                <FAQHeading>What is EtHeroes?</FAQHeading>
                <Paragraph>
                    EtHeroes is a collectable card game running on the Ethereum blockchain. You can claim and trade
                    virtual "Hero" character cards by sending Ethereum transactions.
                </Paragraph>
                <FAQHeading>How much does it cost?</FAQHeading>
                <Paragraph>
                    For a limited time, the claim fee is <b>{Consts.GetPriceString(2e16)}</b>. Unclaimed Heroes are
                    marked with a "Claimable" green tag. You will pay an additional small "gas fee" necessary to make a
                    transaction on the Ethereum blockchain.
                </Paragraph>
                <Paragraph>To buy Heroes from other players, you pay the sell price specified by the player.</Paragraph>
                <FAQHeading>How do I claim a Hero?</FAQHeading>
                <Paragraph>
                    You need an Ethereum wallet in the browser in order to play. A commonly used wallet is{' '}
                    {Consts.ThirdPartyWallet}.{' '}
                    <Consts.CLink onClick={Consts.ThirdPartyWalletOnClick}>Click to install</Consts.CLink>. You'll also
                    need a small amount of fund in your wallet to pay for the transaction fees.
                </Paragraph>
                <Paragraph>
                    Once the wallet is installed and funded, connect your wallet with EtHeroes and start playing!
                </Paragraph>
                <FAQHeading>How many Heroes in total?</FAQHeading>
                <Paragraph>
                    Heroes are released periodically. The maximum number of Heroes is 10,000. Currently, there are{' '}
                    {totalChars} Heroes available.
                </Paragraph>
                <Paragraph>
                    See <Consts.CLink href="/roadmap">Roadmap</Consts.CLink> for details.
                </Paragraph>
                <FAQHeading>Who drew the character arts?</FAQHeading>
                <Paragraph>
                    A computer program! Recent development in computer artificial intelligence enables drawing of
                    realistic portraits. We used similar techniques on fantasy arts and what you are looking at is the
                    result.
                </Paragraph>
                <FAQHeading id="leveling">How does the leveling system work?</FAQHeading>
                <Paragraph>
                    Leveling up your Hero makes it more valuable. Heroes can be leveled up by the owner, one level at a
                    time. Heroes eligible are marked with a purple up arrow sign at the bottom left corner of the
                    character art.
                </Paragraph>
                <Paragraph>
                    Each level requires a wait time calculated in block numbers. The higher the levels, the longer the
                    wait time. Leveling up a Hero will also improve its score.
                </Paragraph>
                <Paragraph>
                    Both the level and the score will be reflected on the character card. There might be a slight delay
                    in the card update. Try refreshing the page if you don't see it.
                </Paragraph>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th isNumeric display="table-cell">
                                Level
                            </Th>
                            <Th isNumeric display="table-cell">
                                Wait Time (Est.)
                            </Th>
                            <Th isNumeric display="table-cell">
                                Score
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody fontFamily="monospace">
                        <Tr>
                            <Td isNumeric>2</Td>
                            <Td isNumeric>1.6 Hours</Td>
                            <Td isNumeric>+1</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>3</Td>
                            <Td isNumeric>2.4 Hours</Td>
                            <Td isNumeric>+1</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>4</Td>
                            <Td isNumeric>3.7 Hours</Td>
                            <Td isNumeric>+1</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>5</Td>
                            <Td isNumeric>5.5 Hours</Td>
                            <Td isNumeric>+1</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>6</Td>
                            <Td isNumeric>8.2 Hours</Td>
                            <Td isNumeric>+1</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>7</Td>
                            <Td isNumeric>12.3 Hours</Td>
                            <Td isNumeric>+1</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>8</Td>
                            <Td isNumeric>18.5 Hours</Td>
                            <Td isNumeric>+1</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>9</Td>
                            <Td isNumeric>27.8 Hours</Td>
                            <Td isNumeric>+1</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>10</Td>
                            <Td isNumeric>42 Hours</Td>
                            <Td isNumeric>+1</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>11</Td>
                            <Td isNumeric>62 Hours</Td>
                            <Td isNumeric>+1</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>12</Td>
                            <Td isNumeric>4 Days</Td>
                            <Td isNumeric>+1</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>13</Td>
                            <Td isNumeric>6 Days</Td>
                            <Td isNumeric>+1</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>14</Td>
                            <Td isNumeric>9 Days</Td>
                            <Td isNumeric>+1</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>15</Td>
                            <Td isNumeric>13 Days</Td>
                            <Td isNumeric>+2</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>16</Td>
                            <Td isNumeric>20 Days</Td>
                            <Td isNumeric>+2</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>17</Td>
                            <Td isNumeric>30 Days</Td>
                            <Td isNumeric>+2</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>18</Td>
                            <Td isNumeric>44 Days</Td>
                            <Td isNumeric>+2</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>19</Td>
                            <Td isNumeric>67 Days</Td>
                            <Td isNumeric>+2</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>20</Td>
                            <Td isNumeric>100 Days</Td>
                            <Td isNumeric>+2</Td>
                        </Tr>
                    </Tbody>
                </Table>
                <FAQHeading>My character card is not updating after leveling!</FAQHeading>
                <Paragraph>
                    Character art can somethings take several minutes to update on this site. On third-party site, such
                    as OpenSea, manually refresh the metadata is required.
                </Paragraph>
                <Paragraph mt="50px">
                    Have more questions?{' '}
                    <Consts.CLink href={'mailto:hi@' + Consts.WebsiteURL}>Contact us.</Consts.CLink>
                </Paragraph>
            </Box>
        </Consts.DocumentPage>
    );
};

export default FAQ;
